import { RouteObject } from 'react-router-dom';
import Overview from '../components/pages/sharedComponents/overview/Overview';
import PaOverview from '../components/pages/performanceAttribution/overview/PAOverview.component';
import AifmdOverviewLegacy from '../components/pages/aifmd/overviewLegacy/AifmdOverviewLegacy.component';
import LiquidityOverview from '../components/pages/liquidity/overview/Overview';
import PaOverviewAlternative from '../components/pages/performanceAttribution/overviewAlternative/PAOverviewAlternative.component';
import SrriShareClassOverview from '../components/pages/ucits/srriShareClassOverview/SrriShareClassOverview';
import EsgOverview from '../components/pages/esg/esgOverview/EsgOverview.component';
import PeOverview from '../components/pages/privateEquity/peOverview/PeOverview.component';
import MarketOverview from '../components/pages/manco/marketOverview/MarketOverview.component';
import MancoDashboard from '../components/pages/manco/mancoDashboard/MancoDashboard.component';
import CoverageRatio from '../components/pages/manco/coverageRatio/CoverageRatio.component';
import RaptorLibrary from '../components/pages/utilities/RaptorLibrary/RaptorLibrary.component';
import Deceased from '../components/pages/ucits/deceased/Deceased';
import NurDeceased from '../components/pages/nurs/deceased/Deceased';
import SifRaifDeceased from '../components/pages/sif-raif/deceased/Deceased.component';
import GlobalExposure from '../components/pages/ucits/globalExposure/GlobalExposure';
import StatusChange from '../components/pages/ucits/statusChange/StatusChange.page';
import AggregateLiquidation from '../components/pages/liquidity/aggLiquidation/component';
import Functionality from '../components/pages/ucits/functionality/Functionality';
import RestrictedCompanies from '../components/pages/utilities/RestrictedCompanies/RestrictedCompanies.component';
import EMIR from '../components/pages/manco/emir/emir.component';
import AdepaEMIR from '../components/pages/manco/adepaEmir/adepaEmir.component';
import RaptorExport from '../components/pages/utilities/RaptorExport/RaptorExport.component';
import RaptorExportToday from '../components/pages/utilities/RaptorExport/RaptorExportToday.component';
import KPIMonitoring from '../components/pages/utilities/KPIMonitoring/KPIMonitoring.component';
import MancoExposure from '../components/pages/manco/mancoExposure/MancoExposure.component';
import ShareClassHedgeSummary from '../components/pages/sharedComponents/shareClassHedgeSummary/ShareClassHedgeSummary.component';
import ReportDeliveryOversight from '../components/pages/manco/reportDeliveryOversight/ReportDeliveryOversight.component';
import HedgeDerivativesSelector from '../components/pages/manco/hedgeDerivativesSelector/HedgeDerivativesSelector.component';
import HybridBondsSelector from '../components/pages/manco/hybridBondsSelector/HybridBondsSelector.component';
import MancoCounterpartyExposure from '../components/pages/manco/mancoCounterpartyExposure/MancoCounterpartyExposure.component';
import PositionOversight from '../components/pages/manco/positionOversight/PositionOversight.component';
import LiquidationTime from '../components/pages/manco/liquidationTime/LiquidationTime.component';
import MancoEquity from '../components/pages/manco/asset_class/equity/MancoEquity';
import MancoFixedIncome from '../components/pages/manco/asset_class/fixed_income/FixedIncome';
import MancoCis from '../components/pages/manco/asset_class/cis/Cis';
import KidsFigures from '../components/pages/raidr/kidsFigures/KidsFigures.component';
import Markets from '../components/pages/markets/MarketsMain';
import YieldMain from '../components/pages/yieldCurve/YieldMain';
import QuaysideShareClassHedge from '../components/pages/reportsPages/quaysideShareClassHedge/QuaysideShareClassHedge.component';
import ReportGenerator from '../components/pages/performanceAttribution/reportGenerator/ReportGenerator.component';
import FixedIncomeReportGenerator from '../components/pages/utilities/FixedIncomeReprotGenerator/FixedIncomeReportGenerator.component';
import SanctionsScanner from '../components/pages/manco/sanctionsScanner/SanctionsScanner.component';
import GeneralOverviewPage from '../components/layout/general/GeneralOverviewPage';
import { GeneralPageWrapper } from '../components/layout/general/GeneralPageWrapper';
import AifmdOverview from '../components/pages/aifmd/overview/AifmdOverview.component';

// These are all the geneal overview pages routes
export const OverviewsRoutes = (data: any): RouteObject[] => [
  {
    path: '/:section/overview',
    element: (
      <GeneralPageWrapper pageId={'overview'}>
        <GeneralOverviewPage Component={Overview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/pa-overview',
    element: (
      <GeneralPageWrapper pageId={'pa-overview'}>
        <GeneralOverviewPage Component={PaOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/aifmd-overview',
    element: (
      <GeneralPageWrapper pageId={'aifmd-overview'}>
        <GeneralOverviewPage Component={AifmdOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/aifmd-overview-legacy',
    element: (
      <GeneralPageWrapper pageId={'aifmd-overview-legacy'}>
        <GeneralOverviewPage Component={AifmdOverviewLegacy} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/liquidity-overview',
    element: (
      <GeneralPageWrapper pageId={'liquidity-overview'}>
        <GeneralOverviewPage Component={LiquidityOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/pa-overview-alternative',
    element: (
      <GeneralPageWrapper pageId={'pa-overview-alternative'}>
        <GeneralOverviewPage Component={PaOverviewAlternative} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/srri-share-class-overview',
    element: (
      <GeneralPageWrapper pageId={'srri-share-class-overview'}>
        <GeneralOverviewPage Component={SrriShareClassOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/esg-overview',
    element: (
      <GeneralPageWrapper pageId={'esg-overview'}>
        <GeneralOverviewPage Component={EsgOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/pe-overview',
    element: (
      <GeneralPageWrapper pageId={'pe-overview'}>
        <GeneralOverviewPage Component={PeOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/market-overview',
    element: (
      <GeneralPageWrapper pageId={'market-overview'}>
        <GeneralOverviewPage Component={MarketOverview} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: '/:section/manco-dashboard',
    element: (
      <GeneralPageWrapper pageId={'manco-dashboard'}>
        <GeneralOverviewPage Component={MancoDashboard} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/coverage-ratio',
    element: (
      <GeneralPageWrapper pageId={'coverage-ratio'}>
        <GeneralOverviewPage Component={CoverageRatio} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/library',
    element: (
      <GeneralPageWrapper pageId={'library'}>
        <GeneralOverviewPage Component={RaptorLibrary} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/deceased',
    element: (
      <GeneralPageWrapper pageId={'deceased'}>
        <GeneralOverviewPage Component={Deceased} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/nurs-deceased',
    element: (
      <GeneralPageWrapper pageId={'nurs-deceased'}>
        <GeneralOverviewPage Component={NurDeceased} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/sif-raif-deceased',
    element: (
      <GeneralPageWrapper pageId={'sif-raif-deceased'}>
        <GeneralOverviewPage Component={SifRaifDeceased} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/global-exposure',
    element: (
      <GeneralPageWrapper pageId={'global-exposure'}>
        <GeneralOverviewPage Component={GlobalExposure} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: '/:section/status-change',
    element: (
      <GeneralPageWrapper pageId={'status-change'}>
        <GeneralOverviewPage Component={StatusChange} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/aggregate-liquidation-time',
    element: (
      <GeneralPageWrapper pageId={'aggregate-liquidation-time'}>
        <GeneralOverviewPage Component={AggregateLiquidation} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/functionality',
    element: (
      <GeneralPageWrapper pageId={'functionality'}>
        <GeneralOverviewPage Component={Functionality} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: '/:section/restricted-companies',
    element: (
      <GeneralPageWrapper pageId={'restricted-companies'}>
        <GeneralOverviewPage Component={RestrictedCompanies} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/emir',
    element: (
      <GeneralPageWrapper pageId={'emir'}>
        <GeneralOverviewPage Component={EMIR} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/adepa-emir',
    element: (
      <GeneralPageWrapper pageId={'adepa-emir'}>
        <GeneralOverviewPage Component={AdepaEMIR} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: '/:section/raptor-export',
    element: (
      <GeneralPageWrapper pageId={'raptor-export'}>
        <GeneralOverviewPage Component={RaptorExport} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/raptor-export-today',
    element: (
      <GeneralPageWrapper pageId={'raptor-export-today'}>
        <GeneralOverviewPage Component={RaptorExportToday} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/kpi-monitoring',
    element: (
      <GeneralPageWrapper pageId={'kpi-monitoring'}>
        <GeneralOverviewPage Component={KPIMonitoring} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/manco-exposure',
    element: (
      <GeneralPageWrapper pageId={'manco-exposure'}>
        <GeneralOverviewPage Component={MancoExposure} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/share-class-hedge-summary',
    element: (
      <GeneralPageWrapper pageId={'share-class-hedge-summary'}>
        <GeneralOverviewPage Component={ShareClassHedgeSummary} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/report-delivery-oversight',
    element: (
      <GeneralPageWrapper pageId={'report-delivery-oversight'}>
        <GeneralOverviewPage Component={ReportDeliveryOversight} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/hedge-derivatives-selector',
    element: (
      <GeneralPageWrapper pageId={'hedge-derivatives-selector'}>
        <GeneralOverviewPage Component={HedgeDerivativesSelector} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/hybrid-bonds-selector',
    element: (
      <GeneralPageWrapper pageId={'hybrid-bonds-selector'}>
        <GeneralOverviewPage Component={HybridBondsSelector} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/hybrid-bonds-selector',
    element: (
      <GeneralPageWrapper pageId={'hybrid-bonds-selector'}>
        <GeneralOverviewPage Component={HybridBondsSelector} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/manco-counterparty-exposure',
    element: (
      <GeneralPageWrapper pageId={'manco-counterparty-exposure'}>
        <GeneralOverviewPage Component={MancoCounterpartyExposure} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/manco-position-oversight',
    element: (
      <GeneralPageWrapper pageId={'manco-position-oversight'}>
        <GeneralOverviewPage Component={PositionOversight} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/manco-liquidation-time',
    element: (
      <GeneralPageWrapper pageId={'manco-liquidation-time'}>
        <GeneralOverviewPage Component={LiquidationTime} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/manco-equity',
    element: (
      <GeneralPageWrapper pageId={'manco-equity'}>
        <GeneralOverviewPage Component={MancoEquity} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/manco-fixed-income',
    element: (
      <GeneralPageWrapper pageId={'manco-fixed-income'}>
        <GeneralOverviewPage Component={MancoFixedIncome} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/manco-cis',
    element: (
      <GeneralPageWrapper pageId={'manco-cis'}>
        <GeneralOverviewPage Component={MancoCis} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/kids-figures',
    element: (
      <GeneralPageWrapper pageId={'kids-figures'}>
        <GeneralOverviewPage Component={KidsFigures} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/markets',
    element: (
      <GeneralPageWrapper pageId={'markets'}>
        <GeneralOverviewPage Component={Markets} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/yield-curve',
    element: (
      <GeneralPageWrapper pageId={'yield-curve'}>
        <GeneralOverviewPage Component={YieldMain} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/quayside-share-class-hedge',
    element: (
      <GeneralPageWrapper pageId={'quayside-share-class-hedge'}>
        <GeneralOverviewPage Component={QuaysideShareClassHedge} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: '/:section/pa-report-generator',
    element: (
      <GeneralPageWrapper pageId={'pa-report-generator'}>
        <GeneralOverviewPage Component={ReportGenerator} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/fixed-income-report-generator',
    element: (
      <GeneralPageWrapper pageId={'fixed-income-report-generator'}>
        <GeneralOverviewPage Component={FixedIncomeReportGenerator} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: '/:section/sanctions-scanner',
    element: (
      <GeneralPageWrapper pageId={'sanctions-scanner'}>
        <GeneralOverviewPage Component={SanctionsScanner} />
      </GeneralPageWrapper>
    ),
  },
];
